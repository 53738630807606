import request from '@/plugins/axios'

// 获取积分商品列表
export const apiIntegralGoodsLists = (params: any) =>
    request.get('/integral.business_integral_goods/lists', { params })

// 积分折扣列表
export const apiDiscountLists = () => request.get('/integral.business_integral_goods/discountList')

// 商品分类/单位/供货商/品牌/运费模板列表
// 商品列表
export const apiIntegralGoodsOtherList = (params: any) => request.get('/integral.business_integral_goods/otherList', { params })

// 添加积分商品
export const apiIntegralGoodsAdd = (params: any) =>
    request.post('/integral.business_integral_goods/add', params)

// 编辑积分商品
export const apiIntegralGoodsEdit = (params: any) =>
    request.post('/integral.business_integral_goods/edit', params)

// 批量修改积分商品
export const apiBatchEdit = (params: any) =>
    request.post('/integral.business_integral_goods/batchEdit', params)


// 删除积分商品
export const apiIntegralGoodsDel = (params: any) =>
    request.post('/integral.business_integral_goods/del', params)

// 切换积分商品状态
export const apiIntegralGoodsStatus = (params: any) =>
    request.post('/integral.business_integral_goods/status', params)

// 获取积分商品详情
export const apiIntegralGoodsDetail = (params: any) =>
    request.get('/integral.business_integral_goods/detail', { params })

// 兑换订单列表
export const apiIntegralOrderLists = (params: any) =>
    request.get('/integral.business_integral_order/lists', { params })

// 兑换订单详情
export const apiIntegralOrderDetail = (params: any) =>
    request.get('/integral.business_integral_order/detail', { params })

// 发货信息
export const apiIntegralOrderDeliveryInfo = (params: any) =>
    request.get('/integral.business_integral_order/deliveryInfo', { params })

// 发货
export const apiIntegralGoodsDelivery = (params: any) =>
    request.post('/integral.business_integral_order/delivery', params)

// 确认收货
export const apiIntegralGoodsConfirm = (params: any) =>
    request.post('/integral.business_integral_order/confirm', params)

// 确认收货
export const apiIntegralGoodsCancel = (params: any) =>
    request.post('/integral.business_integral_order/cancel', params)

// 物流查询
export const apiIntegralGoodsLogistics = (params: any) =>
    request.get('/integral.business_integral_order/logistics', { params })


// 新增品牌
export const apiIntegralCategoryAdd = (data: any) => request.post('/integral.integral_category/add', data)

// 品牌列表
export const apiIntegralCategoryLists = (params: any) => request.get('/integral.integral_category/lists', { params })

// 修改品牌状态
export const apiIntegralCategoryStatus = (data: any) => request.post('/integral.integral_category/status', data)

// 删除品牌
export const apiIntegralCategoryDel = (data: any) => request.post('/integral.integral_category/del', data)

// 商品详情
export const apiIntegralCategoryDetail = (id: number) => request.get('/integral.integral_category/detail', { params: { id } })

// 删除品牌
export const apiIntegralCategoryEdit = (data: any) => request.post('/integral.integral_category/edit', data)


//积分商品分类列表
export const apiCategoryLists = (params: any) => request.get('/integral.integral_category/lists', { params })





